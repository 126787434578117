import axios from "axios";
import errorsStore from "./questionnaire/errorsStore";
import * as HttpStatus from "http-status-codes";
import * as Sentry from "@sentry/browser";

axios.defaults.baseURL =
  window.location.protocol + "//" + window.location.host + "/api/";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";
axios.defaults.withCredentials = true;

const SENTRY_ERROR = "error";
const SENTRY_WARNING = "warning";

// todo? move this to index.js https://github.com/axios/axios/issues/2315
axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (error) => {
    if (!error.response) {
      errorsStore.addError("Er ging iets mis, probeer het opnieuw.");
      Sentry.captureMessage("No response from server", SENTRY_ERROR);

      return Promise.reject(error);
    }

    switch (error.response.status) {
      case HttpStatus.INTERNAL_SERVER_ERROR:
        errorsStore.addError("Er ging iets mis, probeer het opnieuw.");
        Sentry.captureMessage("INTERNAL_SERVER_ERROR 500", SENTRY_ERROR);
        break;
      case HttpStatus.UNAUTHORIZED:
      case HttpStatus.FORBIDDEN:
        Sentry.captureMessage(
          error.response.status + " in Client SPA",
          SENTRY_WARNING
        );
        errorsStore.addError("Geen toegang");
        break;
      case HttpStatus.REQUEST_TOO_LONG:
        errorsStore.addError(
          "Bestand is te groot, verklein het bestand en probeer opnieuw."
        );
        break;
      default:
        Sentry.captureMessage(
          "Error: Other status code: " + error.response.status,
          SENTRY_ERROR
        );
        break;
    }

    return Promise.reject(error);
  }
);

export default axios;
