import React from "react";
import { usePromiseTracker } from "react-promise-tracker";

const QuestionnaireLoader = (props) => {
  const { promiseInProgress } = usePromiseTracker({ area: props.area });

  if (promiseInProgress) {
    return <p>Laden...</p>;
  }

  return <>{props.children}</>;
};

export default QuestionnaireLoader;
