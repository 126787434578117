export default {
    OPTIONS_TYPE_BOOL: [
        {
            displayTextKey: "questionnaire.answer.true",
            value: 1,
        },
        {
            displayTextKey: "questionnaire.answer.false",
            value: 0,
        },
    ]
}