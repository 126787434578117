const questionListName = {
  BASIS_GGZ_INTAKE_V1: "intake",
  BASIS_GGZ_INTAKE_V2: "intake-2",
  AGREE_DEPRECATED: "agree",
  AGREE_V2: "agree-v2",
  AGREE_V3: "agree-v3",
  CQ_INDEX: "cq-index", // old
  CQ_INDEX_V51: "cq-index-v51",
  MHC_SF: "MHC-SF",
  MHC_SF_WRAPPED: "MHC-SF-Wrapped",
  SQ48_STAND_ALONE_MHC: "SQ48-stand-alone-mhc",
  SQ48: "SQ48-stand-alone",
  IFOLLOWUP: "ifollowup",
  ADHD_INTAKE: "adhd-intake",
  ADHD_INTAKE_STAND_ALONE: "adhd-intake-stand-alone",
  ADHD_SELF_REPORT: "adhd-self-report",
};

export default questionListName;
