import logo from "../assets/logo.webp";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { queryClient } from "../react-query/provider";
import { CompletedCohortStudy } from "./completed";
import { EducationLocation } from "./types";
import { useTranslation } from "react-i18next";
import LanguageChanger from "../questionnaire/componenets/LanguageChanger";

function CohortStudy() {
  const { t, i18n } = useTranslation();

  const [isCompleted, setIsCompleted] = useState(false);
  const [agrees, setAgrees] = useState<boolean | null>(null);
  const [educationLocation, setEducationLocation] = useState<EducationLocation>(
    EducationLocation.Unanswered
  );

  useEffect(() => {
    // if the user already set agrees to 'yes', sets an education but then changes agrees to 'no', we should reset education (we should NOT save it then)
    if (!agrees) {
      setEducationLocation(EducationLocation.Unanswered);
    }
  }, [agrees]);

  const getIdFromRoute = () => {
    const path = window.location.pathname;
    const parts = path.split("/").filter((part) => part !== "");
    return parts.length > 0 ? parts[parts.length - 1] : "";
  };

  const { isLoading } = useQuery<{
    isCompleted: boolean;
    prefersEnglish: boolean;
  }>(
    ["cohort-study", "is-completed"],
    async () => {
      const res = await axios.get(
        `cohortstudyagreement/${getIdFromRoute()}/is-completed`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setIsCompleted(data.isCompleted);

        if (data.prefersEnglish) {
          i18n.changeLanguage("en");
          localStorage.setItem("userOverridden_i18nextLng", "en");
        } else {
          i18n.changeLanguage("nl");
          localStorage.setItem("userOverridden_i18nextLng", "nl");
        }
      },
    }
  );

  // refresh, show completed page
  const { mutate: save, isLoading: isSaving } = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `cohortstudyagreement/${getIdFromRoute()}/complete`,
        {
          isAgreementAccepted: agrees === true,
          educationLocation: educationLocation,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      setIsCompleted(true);
      queryClient.invalidateQueries(["cohort-study", "is-completed"]);
      queryClient.refetchQueries(["cohort-study", "is-completed"]);
    },
  });

  if (isLoading) {
    return (
      <main className="min-h-screen bg-blue-100 flex items-center justify-center py-12 text-slate-700">
        <div className="flex items-center space-x-2">
          <Loader2 className="w-6 h-6 animate-spin" />
          <p className="text-2xl">{t("cohort-study-client-loading")}</p>
        </div>
      </main>
    );
  }

  if (isCompleted) {
    return <CompletedCohortStudy />;
  }

  return (
    <main className="min-h-screen bg-blue-100 flex items-center justify-center py-12">
      <section className="shadow bg-white px-6 py-12 lg:p-24 rounded-2xl min-h-[90vh] w-[80vw] lg:w-[70vw] text-center">
        <div className="flex items-center justify-center">
          <img src={logo} alt="logo" className="max-w-[160px]" />
        </div>
        <h1 className="mt-10 text-xl lg:text-4xl font-bold text-slate-700">
          REFLECT study
        </h1>

        <div className="relative">
          <LanguageChanger />
        </div>

        <section className="text-left pt-4">
          <h2 className="text-2xl">
            {t("cohort-study-client-info-letter-header")}
          </h2>
          <p>{t("cohort-study-client-intro")}</p>

          <h3 className="font-bold italic">
            {t("cohort-study-client-why-study")}
          </h3>
          <p>{t("cohort-study-client-why-study-answer")}</p>

          <h3 className="font-bold italic">
            {t("cohort-study-client-what-does-participate-mean")}
          </h3>
          <p>{t("cohort-study-client-what-does-participate-mean-answer-1")}</p>

          <p>{t("cohort-study-client-what-does-participate-mean-answer-2")}</p>

          <ul>
            <li>
              {t("cohort-study-client-what-does-participate-mean-answer-3")}
            </li>
            <li>
              {t("cohort-study-client-what-does-participate-mean-answer-4")}
            </li>
          </ul>
          <p>{t("cohort-study-client-what-does-participate-mean-answer-5")}</p>
          <p>{t("cohort-study-client-what-does-participate-mean-answer-6")}</p>

          <h3 className="font-bold italic">
            {t("cohort-study-client-what-is-compensation")}
          </h3>
          <p>{t("cohort-study-client-what-is-compensation-answer")}</p>
          <h3 className="font-bold italic">
            {t("cohort-study-client-what-happens-with-my-data")}
          </h3>
          <p>{t("cohort-study-client-what-happens-with-my-data-answer")}</p>
          <p>{t("cohort-study-client-withdraw-anytime")}</p>

          <h3 className="font-bold italic">
            {t("cohort-study-client-questions")}
          </h3>
          <p>{t("cohort-study-client-questions-where-to-post")}</p>
        </section>

        <section className="text-left mt-16">
          <h2 className="text-2xl">{t("cohort-study-client-consent-title")}</h2>
          <ul>
            <li>{t("cohort-study-client-consent-point-1")}</li>
            <li>{t("cohort-study-client-consent-point-2")}</li>
            <li>{t("cohort-study-client-consent-point-3")}</li>
            <li>{t("cohort-study-client-consent-point-4")}</li>
          </ul>
          <p>{t("cohort-study-client-consent-statements-apply")}</p>
          <fieldset className="border-none p-0 space-y-2 text-lg">
            <div>
              <input
                type="radio"
                id="agree"
                name="doesAgree"
                value="agree"
                checked={agrees === true}
                onChange={() => setAgrees(true)}
              />
              <label htmlFor="agree" className="pl-2">
                {t("cohort-study-client-yes")}
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="disagree"
                name="doesAgree"
                value="disagree"
                checked={agrees === false}
                onChange={() => setAgrees(false)}
              />
              <label htmlFor="disagree" className="pl-2">
                {t("cohort-study-client-no")}
              </label>
            </div>
          </fieldset>

          {agrees && (
            <section className="pt-6">
              <p>{t("cohort-study-client-additional-question-intro")}</p>

              <h3 className="font-bold italic">
                {t("cohort-study-client-where-did-you-get-education")}
              </h3>

              <fieldset className="border-none p-0 space-y-2 text-lg">
                <div>
                  <input
                    type="radio"
                    id="western"
                    name="educationLocation"
                    value="western"
                    checked={educationLocation === EducationLocation.Western}
                    onChange={() =>
                      setEducationLocation(EducationLocation.Western)
                    }
                  />
                  <label htmlFor="agree" className="pl-2">
                    {t("cohort-study-client-education-answer-west")}
                  </label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="nonWestern"
                    name="educationLocation"
                    value="nonWestern"
                    checked={educationLocation === EducationLocation.NonWestern}
                    onChange={() =>
                      setEducationLocation(EducationLocation.NonWestern)
                    }
                  />
                  <label htmlFor="disagree" className="pl-2">
                    {t("cohort-study-client-education-answer-non-west")}
                  </label>
                </div>
              </fieldset>
            </section>
          )}
        </section>

        {agrees != null &&
          (agrees
            ? educationLocation != EducationLocation.Unanswered
            : true) && (
            <div
              className={`${
                agrees == null ? "hidden" : "visible"
              } w-fit mt-6 px-12 py-2 text-white bg-blue-500 cursor-pointer hover:bg-slate-700 rounded`}
              onClick={async () => await save()}
            >
              {isSaving
                ? t("cohort-study-client-submitting")
                : t("cohort-study-client-submit")}
            </div>
          )}
      </section>
    </main>
  );
}

export default CohortStudy;
