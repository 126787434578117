import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationNL from "./resources/translations/nl.json";
import translationEN from "./resources/translations/en.json";
import countries from "i18n-iso-countries";
import countriesEN from "i18n-iso-countries/langs/en.json";
import countriesNL from "i18n-iso-countries/langs/nl.json";

countries.registerLocale(countriesEN);
countries.registerLocale(countriesNL);

const resources = {
  nl: {
    translation: translationNL,
    countries: countries.getNames("nl", { select: "official" }),
  },
  en: {
    translation: translationEN,
    countries: countries.getNames("en", { select: "official" }),
  },
};

i18n
  .use(LanguageDetector) // https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next)
  .init(
    {
      // options: https://www.i18next.com/overview/configuration-options
      resources,
      fallbackLng: "nl",
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      react: {
        wait: true,
        useSuspense: false,
      },
    },
    () => {
      // add api resources
      fetch("/api/translations")
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          Object.keys(res).forEach((key) => {
            i18n.addResources(key, "translation", res[key]);
          });
        })
        .then(() => i18n.changeLanguage(i18n.language))
        .catch((e) => {
          console.error(e);
        });
    }
  );

export default i18n;
