import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./RatingQuestion.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import NL from "i18n-iso-countries/langs/nl.json";
import countries from "i18n-iso-countries";
import {
  getTranslatedQuestion,
  getQuestionKeyStyle,
} from "../../../utils/translatedQuestionBuilder";

countries.registerLocale(NL);

class CountrySelectQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: this.props.givenAnswer ? this.props.givenAnswer : "",
      value: "NL",
    };
  }

  componentDidMount() {
    this.props.emitGivenAnswer(countries.getName(this.state.value, "nl"));
  }

  getOptions() {
    return Object.keys(NL.countries).map((keyName) => (
      <MenuItem value={keyName}>
        {this.props.t("countries:" + keyName)}
      </MenuItem>
    ));
  }

  handleInputChange(e) {
    this.setState(
      {
        value: e.target.value,
      },
      () => {
        this.props.emitGivenAnswer(countries.getName(this.state.value, "nl"));
      }
    );
  }

  render() {
    const questionText = getTranslatedQuestion(
      this.props.t,
      this.props.questionTextKey,
      this.props.questionTextPlaceholders
    );

    return (
      <div className="dropdown-question">
        <p
          dangerouslySetInnerHTML={{
            __html: getTranslatedQuestion(
              this.props.t,
              this.props.questionTextKey,
              this.props.questionTextPlaceholders
            ),
          }}
          className={getQuestionKeyStyle(questionText)}
        />

        <Select
          value={this.state.value}
          children={this.getOptions()}
          onChange={(event) => this.handleInputChange(event)}
        />
      </div>
    );
  }
}

export default withTranslation()(CountrySelectQuestion);
