import React, {Component} from 'react';
import './Button.scss';
import clsx from "clsx";

class Button extends Component {
    render() {
        return (
            <button className={clsx({
                "button": true,
                "button--secondary": this.props.styleSecondary,
                "button--disabled": this.props.isDisabled
            })}
                data-cy={this.props.dataCy}
                onClick={() => this.handleOnClick()}
                disabled={this.props.isDisabled}>
                {this.props.children}
            </button>
        );
    }

    handleOnClick() {
        if (this.props.isDisabled) {
            // do nothing
        } else {
            this.props.onClick();
        }
    }
}

export default Button;
