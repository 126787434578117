import isNullOrWhitespace from "./isNullOrWhitespace";

export default function isEmptyAnswer(answer) {
    if (typeof answer === 'undefined' || answer == null)
        return true;

    if (answer.length === 0)
        return true;

    if (typeof answer === 'string' || answer instanceof String)
        return isNullOrWhitespace(answer);

    return false;
}
