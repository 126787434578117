import SignatureCanvas from "react-signature-canvas";
import logo from "../assets/logo.webp";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { useState } from "react";
import { Loader2 } from "lucide-react";
import { queryClient } from "../react-query/provider";
import { CompletediAgree } from "./completed";
import { iAgree } from "./types";

function iPracticeGermanyiAggree() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);

  const getIdFromRoute = () => {
    const path = window.location.pathname;
    const parts = path.split("/").filter((part) => part !== "");
    return parts.length > 0 ? parts[parts.length - 1] : "";
  };

  const { data, isLoading } = useQuery<iAgree>(
    ["iPracticeDE", "iAgree"],
    async () => {
      const res = await axios.get(`ipracticegermany/${getIdFromRoute()}`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setName(data.name);
        setEmail(data.email);
        setPhone(data.phone);

        if (data.status == "Accepted") {
          setIsCompleted(true);
        }
      },
    }
  );

  // refresh, show completed page
  const { mutate, isLoading: isSaving } = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `ipracticegermany/${getIdFromRoute()}/accept-contract`,
        {
          name,
          email,
          phone,
        },
        {
          responseType: "blob",
        }
      );
      return res.data;
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `iPractice Allgemeine Geschäftsbedingungen.pdf`
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();

      setIsCompleted(true);
      queryClient.invalidateQueries("iPracticeDE");
      queryClient.refetchQueries("iPracticeDE");
    },
  });

  if (isLoading) {
    return (
      <main className="min-h-screen bg-blue-100 flex items-center justify-center py-12 text-slate-700">
        <div className="flex items-center space-x-2">
          <Loader2 className="w-6 h-6 animate-spin" />
          <p className="text-2xl">Loading...</p>
        </div>
      </main>
    );
  }

  if (isCompleted) {
    return <CompletediAgree />;
  }

  return (
    <main className="min-h-screen bg-blue-100 flex items-center justify-center py-12">
      <section className="shadow bg-white px-6 py-12 lg:p-24 rounded-2xl min-h-[90vh] w-[80vw] lg:w-[70vw] text-center">
        <div className="flex items-center justify-center">
          <img src={logo} alt="logo" className="max-w-[160px]" />
        </div>
        <h1 className="mt-10 text-xl lg:text-4xl font-bold text-slate-700">
          iPractice Allgemeine Geschäftsbedingungen
        </h1>

        <section
          style={{ border: "1px solid #e2e8f0" }}
          className="mt-6 lg:mt-12 rounded-lg p-6 lg:p-12 text-left text-slate-700"
        >
          <p className="text-left text-xl text-slate-700">Liebe*r Klient*in,</p>
          <p className="pt-4 text-left text-slate-700">
            bald hast du dein Erstgespräch mit einem*einer unserer
            Psycholog*innen. Als Praxis für psychische Gesundheit arbeiten wir
            mit allgemeinen Geschäftsbedingungen. Darin ist festgelegt, was du
            von uns erwarten kannst und was wir von dir erwarten. Bitte lies
            diese Bedingungen sorgfältig durch. Wenn du Fragen hast, kontaktiere
            uns bitte über{" "}
            <span className="cursor-pointer text-blue-400 hover:underline">
              kontakt@ipractice.de
            </span>
            .
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-1 lg:gap-6">
            <div className="mt-6 grid w-full max-w-sm items-center gap-1.5">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                autoFocus
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="p-2 rounded-lg text-slate-700"
              />
            </div>

            <div className="mt-6 grid w-full max-w-sm items-center gap-1.5">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="p-2 rounded-lg text-slate-700"
              />
            </div>

            <div className="mt-6 grid w-full max-w-sm items-center gap-1.5">
              <label htmlFor="email">Telefon</label>
              <input
                type="phone"
                id="phone"
                placeholder="Telefon"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="p-2 rounded-lg text-slate-700"
              />
            </div>
          </div>

          <h2 className="pt-6 text-xl font-bold">
            Folgendes gilt für iPractice:
          </h2>

          <p className="pt-4">
            Wir sind während deines Behandlungsprogramms alle zwei Wochen online
            für dich erreichbar.
          </p>

          <p className="pt-4">
            Der erste Termin mit dem*der Psycholog*in kann nur stattfinden, wenn
            du uns deinen Nutzer*innen-Namen bei Wire vor deinem ersten Termin
            bei iPractice mitgeteilt hast. Andernfalls müssen wir den Termin
            absagen.
          </p>

          <p className="pt-4">
            Deine Behandlung ist auf ein bestimmtes und erreichbares Ziel
            ausgerichtet. Dein*e Psycholog*in hilft dir, dieses Ziel zu
            formulieren.
          </p>

          <p className="pt-4">
            Nach unserem kostenlosen Erstgespräch haben wir Folgendes
            vereinbart:
          </p>

          <ul className="list-inside list-disc">
            <li className="text-lg">
              € {data!.price} pro Sitzung.{" "}
              {data?.isOngoingSessions
                ? "Sie haben eine Sitzung nach der anderen gebucht."
                : `Du hast ${data!.numberOfSessions} Sitzungen gebucht.`}
            </li>
          </ul>

          <p className="pt-4">
            Wir gehen davon aus, dass du die vereinbarten Termine wahrnimmst.
            Wenn du in einem Notfall deinen Termin nicht wahrnehmen kannst,
            informiere uns mindestens 48 Stunden vor dem Termin. Du kannst
            deinen Termin nur über{" "}
            <span className="cursor-pointer text-blue-400 hover:underline">
              kontakt@ipractice.de
            </span>{" "}
            absagen. Wenn du deinen Termin rechtzeitig absagst, können wir die
            Zeit nutzen, um jemand anderem zu helfen. Wenn du innerhalb von 48
            Stunden vor deinem Termin absagst, müssen wir dir eine Gebühr von
            50,00 € für das Nichterscheinen berechnen.
          </p>

          <p className="pt-4">
            Wir werden unser Bestes tun, um dir zu helfen. Wenn du nicht
            zufrieden bist, kannst du deine Bedenken mit deinem*deiner
            Psycholog*in besprechen. Wenn du dich dabei nicht wohl fühlst,
            informiere dich bitte über unser Beschwerdeverfahren auf{" "}
            <a
              href="https://ipractice.de/"
              target="_blank"
              className="cursor-pointer text-blue-400 hover:underline"
            >
              www.ipractice.de
            </a>
            .
          </p>

          <p className="pt-4">
            Wir halten es für äußerst wichtig, deine Privatsphäre zu
            respektieren. Alle Informationen, die du mit uns teilst, sind
            vertraulich und werden mit professioneller Verschwiegenheit
            behandelt. Wir halten uns an die Richtlinien des GDPR (General Data
            Protection Regulation) Gesetzes. Du kannst mehr darüber auf unserer
            Website lesen.
          </p>

          <p className="pt-4">
            Wenn du diese Vereinbarung akzeptierst, erklärst du dich mit unseren
            Richtlinien einverstanden.{" "}
          </p>
          <p className="pt-4">
            Indem du auf „Ich stimme zu“ klickst, bestätigst du, dass du die
            obigen Informationen gelesen hast und mit den Arbeitsmethoden von
            iPractice einverstanden bist. Du kannst alle oben genannten
            Informationen jederzeit wieder auf unserer Website nachlesen.
          </p>

          <div className="w-full mt-12 text-left flex items-start justify-center flex-col">
            <p className="pb-2 font-bold text-slate-700">
              Bitte unterschreiben Sie hier:
            </p>
            <div className="border rounded w-full lg:w-[500px] h-[120px] bg-slate-100">
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  className: "sigCanvas",
                  width:
                    window.innerWidth <= 400 ? window.innerWidth - 150 : 500,
                  height: 120,
                }}
              />
            </div>

            <div
              className="w-fit mt-6 px-12 py-2 text-white bg-blue-500 cursor-pointer hover:bg-slate-700 rounded"
              onClick={async () => await mutate()}
            >
              {isSaving ? "Speichern..." : "Ich stimme zu"}
            </div>
          </div>
        </section>
      </section>
    </main>
  );
}

export default iPracticeGermanyiAggree;
