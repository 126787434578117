import React, { Component } from "react";
import "./OpenQuestion.scss";
import { withTranslation } from "react-i18next";
import {
  getTranslatedQuestion,
  getQuestionKeyStyle,
} from "../../../utils/translatedQuestionBuilder";

class OpenQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.givenAnswer ? this.props.givenAnswer : "",
      maxLength: this.props.maxLength ? this.props.maxLength : 1000,
    };
  }

  render() {
    const questionText = getTranslatedQuestion(
      this.props.t,
      this.props.questionTextKey,
      this.props.questionTextPlaceholders
    );

    return (
      <div data-cy="question-key" id={this.props.questionTextKey}>
        <p
          dangerouslySetInnerHTML={{
            __html: questionText,
          }}
          className={getQuestionKeyStyle(questionText)}
        />

        <textarea
          className={"open-question__textarea"}
          value={this.state.value}
          data-cy="open-question"
          onChange={(event) => this.handleChange(event)}
          rows="10"
          cols="50"
        />

        <p className={"open-question__label"}>
          {this.state.value ? this.state.value.length : 0}/
          {this.state.maxLength}
        </p>
      </div>
    );
  }

  handleChange(event) {
    if (event.target.value.length > this.state.maxLength) {
      return event.preventDefault();
    }

    this.setState({ value: event.target.value }, () =>
      this.props.emitGivenAnswer(this.state.value)
    );
  }
}

export default withTranslation()(OpenQuestion);
