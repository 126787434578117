import React, { Component, Fragment } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "../questionnaire/componenets/Questionnaire.scss";
import LanguageChanger from "../questionnaire/componenets/LanguageChanger";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "../questionnaire/componenets/Button";
import "../questionnaire/componenets/Questionnaire.scss";
import axios from "axios";
import patchDataFactory from "../utils/patchDataFactory";
import Loading from "../components/Loading";
import { ScreeningDossierAgree } from "./ScreeningDossierAgree";

class Agree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guid: this.props.guid ? this.props.guid : this.props.match?.params.guid,
      alreadyAgreed: false,
      userAgreements: null,
      isSubmitButtonClicked: false,
      isResearchChecked: true,
      isShareGeneralPractitionerChecked: true,
      dossierSubType: "",
    };
  }

  componentDidMount() {
    axios.get(`userAgreements/${this.state.guid}`).then((response) => {
      this.setState({
        userAgreements: response.data,
        alreadyAgreed: response.data.generalTermsAndConditions,
      });
    });
  }

  handleSubmit = () => {
    this.setState({
      isSubmitInProgress: true,
      isSubmitButtonClicked: true,
    });

    axios
      .patch(
        `userAgreements/${this.state.guid}`,
        patchDataFactory({
          generalTermsAndConditions: true,
          research: this.state.isResearchChecked,
          shareGeneralPractitioner:
            this.state.isShareGeneralPractitionerChecked,
        })
      )
      .then(() => {
        this.setState({
          alreadyAgreed: true,
          isSubmitInProgress: false,
        });

        this.props.onContinue();
      });
  };

  handleToWelcomeOnClick = () => {
    window.location.href =
      window.location.origin +
      "/welcome/" +
      this.state.userAgreements?.dossierLookupGuid;
  };

  renderContent() {
    if (
      typeof this.state.userAgreements === "undefined" ||
      this.state.userAgreements === null
    ) {
      return <Loading />;
    }

    if (this.state.userAgreements.dossierSubType === "SCREENING") {
      return (
        <ScreeningDossierAgree
          userName={this.state.userAgreements.firstName}
          handleSubmit={() => this.handleSubmit()}
          isSubmitInProgress={this.state.isSubmitInProgress}
          isResearchChecked={this.state.isResearchChecked}
          toggleIsResearchChecked={() =>
            this.setState((state) => ({
              isResearchChecked: !state.isResearchChecked,
            }))
          }
          isInformationExchangeChecked={
            this.state.isShareGeneralPractitionerChecked
          }
          toggleInformationExchangeChecked={() =>
            this.setState((state) => ({
              isShareGeneralPractitionerChecked:
                !state.isShareGeneralPractitionerChecked,
            }))
          }
        />
      );
    }

    let AgreeExplanationKey = "agree-explanation";
    let AgreeTermsKey = "agree-terms";
    let AgreeResearchKey = "agree-research";
    let AgreeShareGpKey = "agree-sharegp";
    let AgreeGeneralTermsKey = "agree-general-terms";

    return (
      <Fragment>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t(AgreeExplanationKey, {
              replace: { firstName: this.state.userAgreements.firstName },
            }),
          }}
        />
        <div
          dangerouslySetInnerHTML={{ __html: this.props.t(AgreeTermsKey) }}
        />

        <FormControlLabel
          label={this.props.t(AgreeResearchKey)}
          control={
            <Checkbox
              checked={this.state.isResearchChecked}
              onChange={() =>
                this.setState((state) => ({
                  isResearchChecked: !state.isResearchChecked,
                }))
              }
              name="research"
              color="primary"
            />
          }
        />

        <FormControlLabel
          label={this.props.t(AgreeShareGpKey)}
          control={
            <Checkbox
              checked={this.state.isShareGeneralPractitionerChecked}
              onChange={() =>
                this.setState((state) => ({
                  isShareGeneralPractitionerChecked:
                    !state.isShareGeneralPractitionerChecked,
                }))
              }
              name="shareGeneralPractitioner"
              color="primary"
            />
          }
        />

        <br />
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t(AgreeGeneralTermsKey),
          }}
        />

        <div className={"questionnaire__button-container"}>
          <Button
            onClick={() => this.handleSubmit()}
            isDisabled={this.state.isSubmitInProgress}
          >
            {this.props.t("agree-end-button")}
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <div className="questionnaire-root">
        <div className={"questionnaire"}>
          <LanguageChanger />
          <h1>{this.props.t("agree-title")}</h1>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Agree);
