import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import IBAN from "iban";
import "./InputFieldQuestion.scss";
import clsx from "clsx";
import {
  getTranslatedQuestion,
  getQuestionKeyStyle,
} from "../../../utils/translatedQuestionBuilder";

class InputFieldIbanQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: this.props.givenAnswer ? this.props.givenAnswer : "",
      isTouched: false,
      isValidIban: false,
    };
  }

  render() {
    const questionText = getTranslatedQuestion(
      this.props.t,
      this.props.questionTextKey,
      this.props.questionTextPlaceholders
    );

    return (
      <div className="input-field-question">
        <p
          dangerouslySetInnerHTML={{
            __html: questionText,
          }}
          className={getQuestionKeyStyle(questionText)}
        />

        <input
          id="iban"
          className={clsx({
            "input-field-question__input": true,
            "input-field-question__input--valid": this.state.isValidIban,
            "input-field-question__input--invalid":
              !this.state.isValidIban && this.state.isTouched,
          })}
          type="text"
          onChange={(e) => this.handleInputChange(e)}
          onBlur={(e) => this.handleBlur(e)}
          value={this.state.inputValue}
          placeholder="NL02 ABNA 0123 4567 89"
          required
        />
        {!this.state.isValidIban && this.state.isTouched && (
          <p id="ibanErrorMessage" className="questionnaire__error">
            {this.props.t("questionnaire.error.iban")}
          </p>
        )}
      </div>
    );
  }

  handleBlur(e) {
    this.setState({
      isTouched: true,
      isValidIban: IBAN.isValid(e.target.value),
    });
  }

  handleInputChange(e) {
    this.setState(
      {
        inputValue: this.formatIban(e.target.value),
        isValidIban: IBAN.isValid(e.target.value),
      },
      () => {
        if (IBAN.isValid(this.state.inputValue)) {
          this.props.emitGivenAnswer(this.state.inputValue.replace(/\s/g, ""));
        } else {
          this.props.emitGivenAnswer("");
        }
      }
    );
  }

  formatIban(value) {
    value = value.toUpperCase();
    value = this.removeSpaces(value);
    value = this.removeSpecialCharacters(value);
    value = this.addSpaces(value);
    value = this.trimUnlessUserTypesSpace(value);

    return value;
  }

  removeSpaces(input) {
    return input.replace(/\s/g, "");
  }

  removeSpecialCharacters(input) {
    return input.replace(/[^\w]/g, "");
  }

  trimUnlessUserTypesSpace(value) {
    if (" \t\n\r\v".indexOf(value.substr(-1)) > -1) {
      value = value + " ";
    } else {
      value = value.trim();
    }
    return value;
  }

  addSpaces(value) {
    let chunks = value.match(/.{1,4}/g);
    if (chunks) {
      value = chunks.join(" ");
    }
    return value;
  }
}

export default withTranslation()(InputFieldIbanQuestion);
