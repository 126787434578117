import React, {Component} from 'react';
import './ProgressBar.scss';

class ProgressBar extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            progressPercentage: this.props.currentQuestionNumber / this.props.totalNumberOfQuestions * 100,
        }
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentQuestionNumber !== this.props.currentQuestionNumber) {
            let progressPercentage = this.props.currentQuestionNumber / this.props.totalNumberOfQuestions * 100;
            
            this.setState({progressPercentage: progressPercentage})
        }
    }

    render() {
        return (
            <div className="progress-bar">
              <span className="progress-bar__bar">
                <span className="progress-bar__progress" style={{width: this.state.progressPercentage + '%'}}> </span>
              </span>
            </div>
        );
    }
}

export default ProgressBar;
