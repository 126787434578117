import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import './RequestErrors.scss';

class RequestErrors extends Component {
    render() {
        return (
            <div className={'request-errors'}>
                {this.props.errorsStore.errors.map((error, index) => {
                    return <div className={'request-error'} key={index}>
                        <div className="request-error__container">
                            <p className={'request-error__messages'} key={index}>{error}</p>
                            <div onClick={() => this.props.errorsStore.removeErrorByIndex(index)}
                                 className={'request-error__dismiss'}>x
                            </div>
                        </div>
                    </div>
                })}
            </div>
        );
    }
}

export default inject('errorsStore')(observer(RequestErrors));
