import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import "./SingleSelectQuestion.scss";
import settableEnum from "../../../utils/constants/settableEnum";
import clsx from "clsx";
import {
  getTranslatedQuestion,
  getQuestionKeyStyle,
} from "../../../utils/translatedQuestionBuilder";

class SingleSelectQuestion extends Component {
  constructor(props) {
    super(props);

    let clarifications = [];
    if (this.props.givenAnswer) {
      clarifications[this.props.givenAnswer.option] =
        this.props.givenAnswer.clarification;
    }

    this.state = {
      selected: this.props.givenAnswer ? this.props.givenAnswer.option : null,
      clarifications: clarifications,
    };
  }

  render() {
    const questionText = getTranslatedQuestion(
      this.props.t,
      this.props.questionTextKey,
      this.props.questionTextPlaceholders
    );

    return (
      <div data-cy="question-key" id={this.props.questionTextKey}>
        <p
          dangerouslySetInnerHTML={{
            __html: questionText,
          }}
          className={getQuestionKeyStyle(questionText)}
        />

        <div>
          {this.props.options.map((option, index) => {
            return (
              <Fragment key={index}>
                <div
                  onClick={() => this.selectOption(option)}
                  data-cy={`option-${index}`}
                  className={clsx({
                    "single-select-options__radio": true,
                    "single-select-options__radio--selected":
                      this.state.selected === option.value,
                  })}
                >
                  <div
                    className={clsx({
                      "single-select-options__radio-input": true,
                      "single-select-options__radio-input--selected":
                        this.state.selected === option.value,
                    })}
                  />

                  <span className="single-select-options__radio-text">
                    {this.props.t(option.displayTextKey)}
                  </span>
                </div>

                {this.state.selected === option.value &&
                  this.isClarificationSettable(option) && (
                    <div>
                      <label>
                        {this.props.t("questionnaire.giveClarification")}(
                        {option.clarificationSettable === settableEnum.REQUIRED
                          ? this.props.t("questionnaire.clarification.required")
                          : this.props.t(
                              "questionnaire.clarification.optional"
                            )}
                        )
                      </label>
                      <textarea
                        rows={2}
                        cols={60}
                        data-cy={"mandatory-text"}
                        onChange={(e) =>
                          this.handleClarificationChange(e, option)
                        }
                        value={this.getClarification(option)}
                      />
                    </div>
                  )}
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  selectOption(option) {
    this.setState(
      {
        selected: option.value,
      },
      () =>
        this.props.emitGivenAnswer({
          option: this.state.selected,
          clarification: this.state.clarifications[this.state.selected],
        })
    );
  }

  handleClarificationChange(e, option) {
    let clarifications = this.state.clarifications;
    clarifications[option.value] = e.target.value;

    this.setState(
      {
        clarifications: clarifications,
      },
      () =>
        this.props.emitGivenAnswer({
          option: this.state.selected,
          clarification: this.state.clarifications[this.state.selected],
        })
    );
  }

  getClarification(option) {
    return this.state.clarifications[option.value];
  }

  isClarificationSettable(option) {
    return (
      option.clarificationSettable === settableEnum.REQUIRED ||
      option.clarificationSettable === settableEnum.OPTIONAL
    );
  }
}

export default withTranslation()(SingleSelectQuestion);
