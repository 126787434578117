import React, { Component, Fragment } from "react";
import ProgressBar from "./ProgressBar";
import LanguageChanger from "./LanguageChanger";
import { withTranslation } from "react-i18next";
import "../styles/index.scss";
import "./Questionnaire.scss";

class ClosedQuestionnaire extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="questionnaire-root">
        <div className={"questionnaire"}>
          <LanguageChanger />
          <h1>{this.props.t("questionnaire.ipractice")}</h1>
          <ProgressBar
            totalNumberOfQuestions={100}
            currentQuestionNumber={100}
          />
          <div>{this.props.t("questionnaire.closed")}</div>
        </div>
      </div>
    );
  }

  renderQuestionnaireContent() {}
}

export default withTranslation()(ClosedQuestionnaire);
