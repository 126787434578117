import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./RatingQuestion.scss";
import clsx from "clsx";
import {
  getTranslatedQuestion,
  getQuestionKeyStyle,
} from "../../../utils/translatedQuestionBuilder";

class RatingQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: this.props.givenAnswer,
    };
  }

  render() {
    const questionText = getTranslatedQuestion(
      this.props.t,
      this.props.questionTextKey,
      this.props.questionTextPlaceholders
    );

    return (
      <div
        data-cy="question-key"
        id={this.props.questionTextKey}
        className="rating"
      >
        <p
          dangerouslySetInnerHTML={{
            __html: questionText,
          }}
          className={getQuestionKeyStyle(questionText)}
        />

        <div>
          <div className="rating__label-right">
            <label>{this.props.t(this.props.config.maximumLabelTextKey)}</label>
          </div>

          <div>
            <label>{this.props.t(this.props.config.minimumLabelTextKey)}</label>
          </div>
        </div>

        <div className="rating__button-container">
          {[
            ...Array(1 + this.props.config.maximum - this.props.config.minimum),
          ].map((x, i) => (
            <div
              key={i}
              className={clsx({
                rating__button: true,
                "rating__button--selected":
                  i + this.props.config.minimum === this.state.rating,
              })}
              data-cy={`rating-question-${i}`}
              onClick={() =>
                this.handleButtonClick(i + this.props.config.minimum)
              }
            >
              {i + this.props.config.minimum}
            </div>
          ))}
        </div>
      </div>
    );
  }

  handleButtonClick(rating) {
    this.setState(
      {
        rating: rating,
      },
      () => this.props.emitGivenAnswer(this.state.rating)
    );
  }
}

export default withTranslation()(RatingQuestion);
