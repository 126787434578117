import React, { Component } from "react";
import LanguageChanger from "../LanguageChanger";
import { withTranslation } from "react-i18next";
import axios from "axios";
import PublishIcon from "@mui/icons-material/Publish";
import "../Questionnaire.scss";
import Button from "@mui/material/Button";
import errorsStore from "../../errorsStore";
import Loading from "../../../components/Loading";

class ReferralLetterQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUploadButtonClicked: false,
      isReferralLetterPresent: false,
      firstName: null,
    };
  }

  componentDidMount() {
    if (this.props.guid) {
      this.fetchReferralLetterContent();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.guid && this.props.guid !== prevProps.guid) {
      this.fetchReferralLetterContent();
    }
  }

  fetchReferralLetterContent() {
      axios.get(`/dossiers/${this.props.guid}/referral-letter-client-page-content`).then((res) => {
      this.setState({
        firstName: res.data.firstName,
        isReferralLetterPresent: res.data.isReferralLetterPresent,
        isReferralLetterMandatory: res.data.isReferralLetterMandatory,
      });
    });
  }

  async uploadReferralLetter(event) {
    this.setState({
      isUploadButtonClicked: true,
    });

    const data = new FormData();

    data.set("FormFile", event.target.files[0]);

    axios
      .post("dossiers/" + this.props.guid + "/referral-letter-client", data)
      .then(
        () => {
          this.setState({
            isUploadButtonClicked: false,
            isReferralLetterPresent: true,
          });
        },
        (error) => {
          errorsStore.addError(error.response.data);
        }
      )
  }

  render() {
    return (
      <div className="input-field-question">
        <div>{this.renderContent()}</div>
        <div className={"questionnaire__button-container"}>
          <Button
            variant="contained"
            className="primary"
            onClick={() => this.props.onSubmit()}
            disabled={!this.state.isReferralLetterPresent}
          >
            {this.props.t("continue")}
          </Button>
        </div>
      </div>
    );
  }

  renderContent() {
    if (this.state.firstName === null) {
      return <Loading />;
    }

    if (this.state.isReferralLetterPresent) {
      return (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.t("upload-referral-letter-success-text", {
                firstName: this.state.firstName ?? "placeholder",
              }),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.t("upload-referral-letter-footer-text"),
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t("upload-referral-letter-mandatory-text", {
              firstName: this.state.firstName ?? "placeholder",
            }),
          }}
        />

        <input
          style={{ display: "none" }}
          accept=".pdf,image/*"
          onChange={(e) => this.uploadReferralLetter(e)}
          id={"uploadInput"}
          type="file"
        />

        <label htmlFor={"uploadInput"}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled={this.state.isUploadButtonClicked}
            component="span"
          >
            <PublishIcon />
            Upload
          </Button>
        </label>

        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t("upload-referral-letter-footer-text"),
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(ReferralLetterQuestion);
