import { observable, action, makeObservable } from "mobx";

class ErrorsModel {
  errors = [];

  constructor() {
    makeObservable(this, {
      errors: observable,
      setErrors: action,
      addError: action,
      clearErrors: action,
      clearLatestError: action,
      removeErrorByIndex: action,
    });
  }

  setErrors(errors) {
    this.errors = errors;
  }

  addError(error) {
    this.errors.unshift(error);
  }

  clearErrors() {
    this.errors = [];
  }

  clearLatestError() {
    this.errors.splice(this.errors.length - 1, 1);
  }

  removeErrorByIndex(index) {
    this.errors.splice(index, 1);
  }
}

const errorsStore = new ErrorsModel();

export default errorsStore;
