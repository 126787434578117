import { observable, action, makeObservable, runInAction } from "mobx";
import axios from "axios";
import questionTypeHelper from "../utils/constants/questionType";

class QuestionnaireStore {
  questionList = null;

  constructor() {
    makeObservable(this, {
      questionListAnswers: observable,
      fetchFirstUnansweredQuestion: action,
      answerQuestion: action,
      fetchNextQuestion: action,
      fetchPreviousQuestion: action,
      finishQuestionList: action,
      fetchQuestionList: action,
    });
  }

  questionListAnswers = [];

  async isOpenQuestionList(questionListGuid) {
    const res = await axios.get(
      `/questionlists/is-open-question-list/${questionListGuid}`
    );

    return res.data;
  }

  async fetchFirstUnansweredQuestion(questionListGuid) {
    return axios
      .get(`questionlists/${questionListGuid}/getfirstopenquestion`)
      .then((res) => {
        return res.data;
      });
  }

  async answerQuestion(
    questionListGuid,
    questionId,
    questionType,
    givenAnswer
  ) {
    let data = {};
    switch (questionType) {
      case questionTypeHelper.BOOL:
        data = {
          boolAnswer: givenAnswer.option,
        };
        break;
      case questionTypeHelper.SINGLE_SELECT:
        data = {
          intAnswer: givenAnswer.option,
        };
        break;
      case questionTypeHelper.INT:
        data = {
          intAnswer: givenAnswer,
        };
        break;
      case questionTypeHelper.MULTI_SELECT:
        let intAnswers = [];
        for (let answerOption of givenAnswer.options) {
          intAnswers.push(answerOption);
        }
        data = {
          IntAnswers: intAnswers,
        };
        break;
      case questionTypeHelper.STRING:
        data = {
          stringAnswer: givenAnswer,
        };
        break;
      case questionTypeHelper.INFO:
        data = {};
        break;
      case questionTypeHelper.MULTI_SELECT_WITH_CLARIFICATION:
        let clarifiedAnswers = [];
        for (let answerOption of givenAnswer.options) {
          clarifiedAnswers.push({
            Answer: answerOption,
            Clarification: givenAnswer.clarifications[answerOption],
          });
        }
        data = {
          ClarifiedAnswers: clarifiedAnswers,
        };
        break;
      case questionTypeHelper.SINGLE_SELECT_WITH_CLARIFICATION:
        data = {
          ClarifiedAnswer: {
            Answer: givenAnswer.option,
            Clarification: givenAnswer.clarification,
          },
        };
        break;
      default:
        console.error("UNKNOWN QUESTION TYPE");
    }

    const res = await axios.put(
      `questionlists/${questionListGuid}/questions/${questionId}`,
      data
    );

    runInAction(() => {
      this.questionListAnswers.push(res.data);
    });

    return res.data;
  }

  async fetchQuestionList(questionListGuid) {
    return axios.get(`questionlists/${questionListGuid}`).then((res) => {
      return res.data;
    });
  }

  async fetchNextQuestion(questionListGuid, questionId) {
    return axios
      .get(
        `questionlists/${questionListGuid}/getnextquestion?questionId=${questionId}`
      )
      .then((res) => {
        return res.data;
      });
  }

  async fetchPreviousQuestion(questionListGuid, questionId) {
    return axios
      .get(
        `questionlists/${questionListGuid}/getpreviousquestion?questionId=${questionId}`
      )
      .then((res) => {
        return res.data;
      });
  }

  async finishQuestionList(questionListGuid) {
    return axios.patch(`questionlists/${questionListGuid}/finish`);
  }
}

const questionnaireStore = new QuestionnaireStore();

export default questionnaireStore;
