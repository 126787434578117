import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./InputFieldQuestion.scss";
import isEmptyAnswer from "../../../utils/isEmptyAnswer";
import clsx from "clsx";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  getTranslatedQuestion,
  getQuestionKeyStyle,
} from "../../../utils/translatedQuestionBuilder";

class InputFieldMobileQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: this.props.givenAnswer ? this.props.givenAnswer : "",
      isValidPhone: false,
      isTouched: false,
    };
  }

  render() {
    const questionText = getTranslatedQuestion(
      this.props.t,
      this.props.questionTextKey,
      this.props.questionTextPlaceholders
    );

    return (
      <div className="input-field-question">
        <p
          dangerouslySetInnerHTML={{
            __html: questionText,
          }}
          className={getQuestionKeyStyle(questionText)}
        />

        <PhoneInput
          placeholder={this.props.placeholder}
          value={this.inputValue}
          onChange={(e164FormattedPhoneNumber) =>
            this.onChange(e164FormattedPhoneNumber)
          }
          numberInputProps={{
            className: clsx({
              "input-field-question__input": true,
              "input-field-question__input--valid":
                this.state.isTouched && this.state.isValidPhone,
              "input-field-question__input--invalid":
                this.state.isTouched && !this.state.isValidPhone,
            }),
          }}
          onBlur={() => this.handleBlur()}
          defaultCountry="NL"
        />
        {!this.state.isValidPhone && this.state.isTouched && (
          <p id="phoneErrorMessage" className="questionnaire__error">
            {this.props.t("questionnaire.error.phone")}
          </p>
        )}
      </div>
    );
  }

  handleBlur() {
    this.setState({
      isTouched: true,
      isValidPhone: this.isValidNumber(this.state.inputValue),
    });
  }

  /**
   * @param e164FormattedPhoneNumber Accepts a string which is formatted according to the e.164 standard [+][country code][number]
   */
  onChange(e164FormattedPhoneNumber) {
    this.setState(
      {
        inputValue: e164FormattedPhoneNumber,
        isValidPhone: this.isValidNumber(this.state.inputValue),
      },
      () => {
        if (this.isValidNumber(this.state.inputValue)) {
          this.props.emitGivenAnswer(this.state.inputValue);
        } else {
          this.props.emitGivenAnswer("");
        }
      }
    );
  }

  isValidNumber(value) {
    return !isEmptyAnswer(value) && isValidPhoneNumber(value);
  }
}

export default withTranslation()(InputFieldMobileQuestion);
