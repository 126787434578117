export const getTranslatedQuestion = (t, questionTextKey, placeholders) => {
  let translatedQuestionWithPlaceholders = t(questionTextKey);

  Object.keys(placeholders).forEach((x) => {
    translatedQuestionWithPlaceholders =
      translatedQuestionWithPlaceholders.replace(`{{${x}}}`, placeholders[x]);
  });

  return t(translatedQuestionWithPlaceholders);
};

export const getQuestionKeyStyle = (questionText) => {
  return questionText.length > 200 ? "text-xl" : "text-2xl";
};
