import axios from "axios";
import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey: [url] }) => {
        if (typeof url === "string") {
          const { data } = await axios.get(`${url.toLowerCase()}`);
          return data;
        }
        throw new Error("Invalid QueryKey");
      },
    },
  },
});
