import React, { Component } from "react";
import LanguageChanger from "../questionnaire/componenets/LanguageChanger";
import { withTranslation } from "react-i18next";
import axios from "axios";
import PublishIcon from "@mui/icons-material/Publish";
import "../questionnaire/componenets/Questionnaire.scss";
import "./Registration.scss";
import Button from "@mui/material/Button";
import errorsStore from "../questionnaire/errorsStore";
import Loading from "../components/Loading";

class ReferralLetter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guid: this.props.guid ? this.props.guid : this.props.match.params.guid,
      isUploadButtonClicked: false,
      isReferralLetterPresent: false,
      firstName: null,
    };
  }

  componentDidMount() {
    axios
      .get(`/dossiers/${this.state.guid}/referral-letter-client-page-content`)
      .then((res) => {
        this.setState({
          firstName: res.data.firstName,
          isReferralLetterPresent: res.data.isReferralLetterPresent,
          isReferralLetterMandatory: res.data.isReferralLetterMandatory,
        });
      });
  }

  async uploadReferralLetter(event) {
    this.setState({
      isUploadButtonClicked: true,
    });

    const data = new FormData();

    data.set("FormFile", event.target.files[0]);

    axios
      .post("dossiers/" + this.state.guid + "/referral-letter-client", data)
      .then(
        () => {
          this.setState({
            isUploadButtonClicked: false,
            isReferralLetterPresent: true,
          });

          this.props.onContinue();
        },
        (error) => {
          errorsStore.addError(error.response.data);
        }
      );
  }

  render() {
    return (
      <div className="questionnaire-root registration-container">
        <div>
          <LanguageChanger />
          <h1>{this.props.t("upload-referral-letter-title")}</h1>

          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderContent() {
    if (this.state.firstName === null) {
      return <Loading />;
    }

    if (this.state.isReferralLetterPresent) {
      return (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.t("upload-referral-letter-success-text", {
                firstName: this.state.firstName ?? "placeholder",
              }),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.t("upload-referral-letter-footer-text"),
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t(
              this.state.isReferralLetterMandatory
                ? "upload-referral-letter-mandatory-text"
                : "upload-referral-letter-text",
              {
                firstName: this.state.firstName ?? "placeholder",
              }
            ),
          }}
        />

        <input
          style={{ display: "none" }}
          accept=".pdf,image/*"
          onChange={(e) => this.uploadReferralLetter(e)}
          id={"uploadInput"}
          type="file"
        />

        <label htmlFor={"uploadInput"}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled={this.state.isUploadButtonClicked}
            component="span"
          >
            <PublishIcon />
            Upload
          </Button>
        </label>

        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t("upload-referral-letter-footer-text"),
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(ReferralLetter);
