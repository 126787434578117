import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import "./MultipleSelectQuestion.scss";
import settableEnum from "../../../utils/constants/settableEnum";
import clsx from "clsx";
import {
  getTranslatedQuestion,
  getQuestionKeyStyle,
} from "../../../utils/translatedQuestionBuilder";

class MultipleSelectQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.generateSelectedFromGivenAnswer(this.props.givenAnswer),
      clarifications: this.generateClarificationsFromGivenAnswer(
        this.props.givenAnswer
      ),
    };
  }

  render() {
    const questionText = getTranslatedQuestion(
      this.props.t,
      this.props.questionTextKey,
      this.props.questionTextPlaceholders
    );

    return (
      <div data-cy="question-key" id={this.props.questionTextKey}>
        <p
          dangerouslySetInnerHTML={{
            __html: questionText,
          }}
          className={getQuestionKeyStyle(questionText)}
        />

        <div>
          {this.props.options.map((option, index) => {
            return (
              <Fragment key={index}>
                <div
                  onClick={() => this.toggleOption(option)}
                  data-cy={`multi-option-${option.value}`}
                  className={clsx({
                    "multiple-select-options__checkbox": true,
                    "multiple-select-options__checkbox--selected":
                      this.isSelected(option),
                  })}
                >
                  <div
                    className={clsx({
                      "multiple-select-options__checkbox-input": true,
                      "multiple-select-options__checkbox-input--selected":
                        this.isSelected(option),
                    })}
                  />

                  <span className="multiple-select-options__checkbox-text">
                    {this.props.t(option.displayTextKey)}
                  </span>
                </div>
                {this.isSelected(option) &&
                  this.isClarificationSettable(option) && (
                    <div>
                      <label>
                        {this.props.t("questionnaire.giveClarification")}(
                        {option.clarificationSettable === settableEnum.REQUIRED
                          ? this.props.t("questionnaire.clarification.required")
                          : this.props.t(
                              "questionnaire.clarification.optional"
                            )}
                        )
                      </label>
                      <textarea
                        rows={2}
                        cols={60}
                        data-cy={
                          option.clarificationSettable === settableEnum.REQUIRED
                            ? "mandatory-text"
                            : null
                        }
                        onChange={(e) =>
                          this.handleClarificationChange(e, option)
                        }
                        value={this.getClarification(option)}
                      />
                    </div>
                  )}
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  toggleOption(option) {
    let selected = this.state.selected;
    const existingIndex = selected.indexOf(option.value);

    if (existingIndex > -1) {
      selected.splice(existingIndex, 1);

      this.setState(
        {
          selected: selected,
        },
        () => {
          if (this.state.selected.length < this.props.minimumNumberOfItems) {
            this.props.emitGivenAnswer([]);
          } else {
            this.props.emitGivenAnswer({
              options: this.state.selected,
              clarifications: this.state.clarifications,
            });
          }
        }
      );
    } else {
      if (
        this.props.maximumNumberOfItems &&
        this.state.selected.length === this.props.maximumNumberOfItems
      ) {
        // Do nothing, or shake or some other feedback.
      } else {
        selected.push(option.value);

        this.setState(
          {
            selected: selected,
          },
          () => {
            if (this.state.selected.length >= this.props.minimumNumberOfItems) {
              this.props.emitGivenAnswer({
                options: this.state.selected,
                clarifications: this.state.clarifications,
              });
            }
          }
        );
      }
    }
  }

  handleClarificationChange(e, option) {
    let clarifications = this.state.clarifications;
    clarifications[option.value] = e.target.value;

    this.setState(
      {
        clarifications: clarifications,
      },
      () =>
        this.props.emitGivenAnswer({
          options: this.state.selected,
          clarifications: this.state.clarifications,
        })
    );
  }

  getClarification(option) {
    return this.state.clarifications[option.value]
      ? this.state.clarifications[option.value]
      : "";
  }

  isSelected(option) {
    return this.state.selected.indexOf(option.value) > -1;
  }

  generateSelectedFromGivenAnswer(givenAnswer) {
    let selected = [];

    for (let answer of givenAnswer) {
      selected.push(answer.value);
    }

    return selected;
  }

  generateClarificationsFromGivenAnswer(givenAnswer) {
    let clarifications = [];

    for (let option of this.props.options) {
      if (option.clarificationSettable === settableEnum.NOT) {
        // don't add empty clarification
      } else {
        clarifications[option.value] = "";
      }
    }

    if (givenAnswer === null) {
      return clarifications;
    }

    for (let answer of givenAnswer) {
      clarifications[answer.value] = answer.clarification
        ? answer.clarification
        : null;
    }

    return clarifications;
  }

  isClarificationSettable(option) {
    return (
      option.clarificationSettable === settableEnum.REQUIRED ||
      option.clarificationSettable === settableEnum.OPTIONAL
    );
  }
}

export default withTranslation()(MultipleSelectQuestion);
