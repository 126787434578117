class ReplaceOperation {
    op = "replace";
    path = "";
    value = "";

    constructor(path, value) {
        this.path = "/" + path;
        this.value = value;
    }
}

export default (replacements) => {
    let arrayOfOperations = [];

    Object.keys(replacements).map((key, index) => (
        arrayOfOperations.push(new ReplaceOperation(key, replacements[key]))
    ));

    return arrayOfOperations;
}
