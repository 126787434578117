import React, { Component, Fragment } from "react";
import ProgressBar from "./ProgressBar";
import SingleSelectQuestion from "./question_views/SingleSelectQuestion";
import InfoPage from "./question_views/InfoPage";
import InputFieldIbanQuestion from "./question_views/InputFieldIbanQuestion";
import InputFieldTextQuestion from "./question_views/InputFieldTextQuestion";
import InputFieldNumberQuestion from "./question_views/InputFieldNumberQuestion";
import questionListName from "../../utils/constants/questionListName";
import questionView from "../../utils/constants/questionView";
import MultipleSelectQuestion from "./question_views/MultipleSelectQuestion";
import answerType from "../../utils/constants/answerType";
import RatingQuestion from "./question_views/RatingQuestion";
import questionType from "../../utils/constants/questionType";
import isNullOrWhitespace from "../../utils/isNullOrWhitespace";
import LanguageChanger from "./LanguageChanger";
import questionnaireStore from "../questionnaireStore";
import Button from "./Button";
import settableEnum from "../../utils/constants/settableEnum";
import isEmptyAnswer from "../../utils/isEmptyAnswer";
import { withTranslation } from "react-i18next";
import OpenQuestion from "./question_views/OpenQuestion";
import "../styles/index.scss";
import "./Questionnaire.scss";
import CountrySelectQuestion from "./question_views/CountrySelectQuestion";
import InputFieldMobileQuestion from "./question_views/InputFieldMobileQuestion";
import WireUsernameQuestion from "./question_views/WireUsernameQuestion";
import { trackPromise } from "react-promise-tracker";
import QuestionnaireLoader from "./QuestionnaireLoader";
import Loading from "../../components/Loading";
import ReferralLetterQuestion from "./question_views/ReferralLetterQuestion";

const KeySymptomsQuestion = "question-symptoms";
const KeyCopingQuestion = "question-coping";

class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAgreeQuestionList: false,
      questionListGuid: this.props.guid
        ? this.props.guid
        : this.props.match.params.guid,
      totalNumberOfQuestions: Number.MAX_SAFE_INTEGER,
      questionListName: null,
      dossierType: null,
      dossierId: null,
      healthCareInsurerType: null,
      firstUnansweredQuestionNumber: 1,
      isClosed: false,
      displayWireUsernameQuestion: false,
      displayReferralLetterQuestion: false,
      isFinishButtonClicked: false,

      questionId: null,
      questionKey: null,
      questionType: null,
      questionNumber: 1,
      questionTextKey: "[vraag]",
      answerOptions: [],
      givenAnswer: null,
      newAnswer: null,
      isAnswered: false,
      questionTextPlaceholders: null,
      isNextButtonClicked: false,
      view: null,
      viewConfig: null,
    };

    this.firstQuestionNumber = 1;
  }

  componentDidMount() {
    questionnaireStore.isOpenQuestionList(this.state.questionListGuid).then(
      (isOpenQuestionList) => {
        if (isOpenQuestionList) {
          questionnaireStore
            .fetchQuestionList(this.state.questionListGuid)
            .then((questionList) => {
              if (questionList.name === questionListName.AGREE_V3) {
                this.setState({ isAgreeQuestionList: true });
              } else {
                this.setState({
                  totalNumberOfQuestions: questionList.questions.length,
                  questionListName: questionList.name,
                  dossierType: questionList.dossierType,
                  dossierId: questionList.dossierId,
                  healthCareInsurerType: questionList.healthCareInsurerType,
                });
                this.loadFirstUnansweredQuestion();
              }
            });
        } else {
          this.setState((state, props) => ({
            isClosed: true,
            questionNumber: state.totalNumberOfQuestions,
          }));
        }
      },
      () => {
        this.setState((state, props) => ({
          isClosed: true,
          questionNumber: state.totalNumberOfQuestions,
        }));
      }
    );
  }

  render() {
    if (this.state.isAgreeQuestionList) {
      return <div>Deze versie van de iAgree is niet meer in gebruik.</div>;
    }

    return (
      <div className="questionnaire-root">
        <div className={"questionnaire"}>
          <LanguageChanger />
          <h1>{this.props.t("questionnaire.ipractice")}</h1>
          <ProgressBar
            totalNumberOfQuestions={this.state.totalNumberOfQuestions}
            currentQuestionNumber={this.state.questionNumber}
          />
          {this.renderQuestionnaireContent()}
        </div>
      </div>
    );
  }

  renderQuestionnaireContent() {
    if (this.state.displayReferralLetterQuestion) {
      return (
        <ReferralLetterQuestion
          guid={this.state.dossierId}
          healthCareInsurerType={this.props.healthCareInsurerType}
          questionListGuid={this.state.questionListGuid}
          onSubmit={() =>
            this.setState({ displayReferralLetterQuestion: false })
          }
        />
      );
    }

    if (this.state.isClosed) {
      if (
        this.props.welcomeStage &&
        this.props.welcomeStage === "questionnaire"
      ) {
        this.props.onContinue();
      }
      return <div>{this.props.t("questionnaire.closed")}</div>;
    }

    if (this.state.questionKey === "q-last") {
      return (
        <Fragment>
          {this.renderQuestion()}

          <div className={"questionnaire__error"}>{this.state.userError}</div>
          <div className={"questionnaire__button-container"}>
            <QuestionnaireLoader area={"finish-buttons-area"}>
              <Button
                onClick={() => this.handlePreviousClick()}
                isDisabled={this.state.isFinishButtonClicked}
                styleSecondary={true}
              >
                {this.props.t("questionnaire.previousButton")}
              </Button>
              <Button
                onClick={() => this.handleFinishClick()}
                dataCy="end-button"
                isDisabled={this.state.isFinishButtonClicked}
              >
              {this.props.t("questionnaire.endButton")}
              </Button>
            </QuestionnaireLoader>
          </div>
        </Fragment>
      );
    }

    if (this.firstQuestionNumber === this.state.questionNumber) {
      return (
        <Fragment>
          {this.renderQuestion()}

          <div className={"questionnaire__button-container"}>
            <Button onClick={() => this.handleNextClick()} dataCy="next-button">
              {this.props.t("questionnaire.startButton")}
            </Button>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {this.renderQuestion()}

        <div className={"questionnaire__error"}>{this.state.userError}</div>
        <div className={"questionnaire__button-container"}>
          <Button
            onClick={() => this.handlePreviousClick()}
            styleSecondary={true}
            dataCy="previous-button"
          >
            {this.props.t("questionnaire.previousButton")}
          </Button>
          <Button
            onClick={() => this.handleNextClick()}
            dataCy="next-button"
            isDisabled={!this.isNextButtonEnabled()}
          >
            {this.props.t("questionnaire.nextButton")}
          </Button>
        </div>
      </Fragment>
    );
  }

  renderQuestion() {
    switch (this.state.questionType) {
      case questionType.BOOL:
        return (
          <SingleSelectQuestion
            key={this.state.questionId}
            questionTextKey={this.state.questionTextKey}
            questionTextPlaceholders={this.state.questionTextPlaceholders}
            options={answerType.OPTIONS_TYPE_BOOL}
            givenAnswer={this.state.givenAnswer}
            emitGivenAnswer={(givenAnswer) =>
              this.handleAnswerUpdate(givenAnswer)
            }
          />
        );
      case questionType.SINGLE_SELECT:
      case questionType.SINGLE_SELECT_WITH_CLARIFICATION:
        return (
          <SingleSelectQuestion
            key={this.state.questionId}
            questionTextKey={this.state.questionTextKey}
            questionTextPlaceholders={this.state.questionTextPlaceholders}
            options={this.state.answerOptions}
            givenAnswer={this.state.givenAnswer}
            emitGivenAnswer={(givenAnswer) =>
              this.handleAnswerUpdate(givenAnswer)
            }
          />
        );
      case questionType.MULTI_SELECT:
      case questionType.MULTI_SELECT_WITH_CLARIFICATION:
        return (
          <MultipleSelectQuestion
            key={this.state.questionId}
            questionTextKey={this.state.questionTextKey}
            questionTextPlaceholders={this.state.questionTextPlaceholders}
            options={this.state.answerOptions}
            givenAnswer={this.state.givenAnswer}
            emitGivenAnswer={(givenAnswer) =>
              this.handleAnswerUpdate(givenAnswer)
            }
            maximumNumberOfItems={this.getMaximumNumberOfItems()}
            minimumNumberOfItems={this.getMinimumNumberOfItems()}
          />
        );
      case questionType.STRING:
        if (this.state.questionView === questionView.IBAN) {
          return (
            <InputFieldIbanQuestion
              key={this.state.questionId}
              questionTextKey={this.state.questionTextKey}
              questionTextPlaceholders={this.state.questionTextPlaceholders}
              givenAnswer={this.state.givenAnswer}
              emitGivenAnswer={(givenAnswer) =>
                this.handleAnswerUpdate(givenAnswer)
              }
            />
          );
        } else if (this.state.questionView === questionView.SINGLE_LINE) {
          return (
            <InputFieldTextQuestion
              key={this.state.questionId}
              questionTextKey={this.state.questionTextKey}
              questionTextPlaceholders={this.state.questionTextPlaceholders}
              givenAnswer={this.state.givenAnswer}
              emitGivenAnswer={(givenAnswer) =>
                this.handleAnswerUpdate(givenAnswer)
              }
            />
          );
        } else if (
          this.state.questionView === questionView.MOBILE_PHONE_NUMBER
        ) {
          return (
            <InputFieldMobileQuestion
              key={this.state.questionId}
              questionTextKey={this.state.questionTextKey}
              questionTextPlaceholders={this.state.questionTextPlaceholders}
              placeholder="+31 6 1234 5678"
              givenAnswer={this.state.givenAnswer}
              emitGivenAnswer={(givenAnswer) =>
                this.handleAnswerUpdate(givenAnswer)
              }
            />
          );
        } else if (this.state.questionView === questionView.COUNTRY_SELECT) {
          return (
            <CountrySelectQuestion
              key={this.state.questionId}
              questionTextKey={this.state.questionTextKey}
              questionTextPlaceholders={this.state.questionTextPlaceholders}
              givenAnswer={this.state.givenAnswer}
              emitGivenAnswer={(givenAnswer) =>
                this.handleAnswerUpdate(givenAnswer)
              }
            />
          );
        } else {
          return (
            <OpenQuestion
              key={this.state.questionId}
              questionTextKey={this.state.questionTextKey}
              questionTextPlaceholders={this.state.questionTextPlaceholders}
              givenAnswer={this.state.givenAnswer}
              emitGivenAnswer={(givenAnswer) =>
                this.handleAnswerUpdate(givenAnswer)
              }
            />
          );
        }
      case questionType.INT:
        if (this.state.questionView === questionView.RATING) {
          return (
            <RatingQuestion
              key={this.state.questionId}
              questionTextKey={this.state.questionTextKey}
              questionTextPlaceholders={this.state.questionTextPlaceholders}
              givenAnswer={this.state.givenAnswer}
              emitGivenAnswer={(givenAnswer) =>
                this.handleAnswerUpdate(givenAnswer)
              }
              config={this.state.viewConfig}
            />
          );
        } else {
          return (
            <InputFieldNumberQuestion
              key={this.state.questionId}
              questionTextKey={this.state.questionTextKey}
              questionTextPlaceholders={this.state.questionTextPlaceholders}
              givenAnswer={this.state.givenAnswer}
              emitGivenAnswer={(givenAnswer) =>
                this.handleAnswerUpdate(givenAnswer)
              }
            />
          );
        }
      case questionType.INFO:
        return (
          <InfoPage
            key={this.state.questionId}
            questionTextPlaceholders={this.state.questionTextPlaceholders}
            questionTextKey={this.state.questionTextKey}
          />
        );
      default:
        return <Loading />;
    }
  }

  handleFinishClick() {
    if (this.state.isFinishButtonClicked) return;

    if (this.isNextButtonEnabled()) {
      if (
        this.state.newAnswer ||
        this.state.questionType === questionType.INFO
      ) {
        this.setState({ isFinishButtonClicked: true });
        trackPromise(
          questionnaireStore
            .answerQuestion(
              this.state.questionListGuid,
              this.state.questionId,
              this.state.questionType,
              this.state.newAnswer
            )
            .then(() =>
              questionnaireStore.finishQuestionList(this.state.questionListGuid)
            )
            .then(() =>
              this.setState((state, props) => ({
                isClosed: true,
              }))
            ),
          "finish-buttons-area"
        );
      }
    } else {
      this.setState({
        userError: this.props.t("questionnaire.error.select"),
      });
    }
  }

  handleAnswerUpdate(givenAnswer) {
    this.setState({
      newAnswer: givenAnswer,
      userError: "",
    });
  }

  handleNextClick() {
    if (this.state.isNextButtonClicked) return;

    if (this.isNextButtonEnabled()) {
      if (
        this.state.newAnswer ||
        this.state.questionType === questionType.INFO
      ) {
        this.setState({ isNextButtonClicked: true });
        questionnaireStore
          .answerQuestion(
            this.state.questionListGuid,
            this.state.questionId,
            this.state.questionType,
            this.state.newAnswer
          )
          .then(() => this.loadNextQuestion());
      } else {
        this.loadNextQuestion();
      }
    } else {
      this.setState({
        userError: this.props.t("questionnaire.error.select"),
      });
    }
  }

  loadFirstUnansweredQuestion() {
    questionnaireStore
      .fetchFirstUnansweredQuestion(this.state.questionListGuid)
      .then((question) => this.updateQuestionState(question));
  }

  loadNextQuestion() {
    questionnaireStore
      .fetchNextQuestion(this.state.questionListGuid, this.state.questionId)
      .then((question) => this.updateQuestionState(question));
  }

  handlePreviousClick() {
    this.setState({
      userError: this.props.t(""),
    });

    questionnaireStore
      .fetchPreviousQuestion(this.state.questionListGuid, this.state.questionId)
      .then((question) => this.updateQuestionState(question));
  }

  isOptionalQuestion() {
    return (
      this.state.questionType === questionType.INFO ||
      this.state.questionView === questionView.WIRE_GUIDE
    );
  }

  isNextButtonEnabled() {
    if (this.isOptionalQuestion()) {
      return true;
    }

    switch (this.state.questionType) {
      case questionType.SINGLE_SELECT_WITH_CLARIFICATION:
        if (this.state.newAnswer && this.state.newAnswer.option) {
          for (let option of this.state.answerOptions) {
            if (
              this.state.newAnswer.option === option.value &&
              option.clarificationSettable === settableEnum.REQUIRED &&
              isNullOrWhitespace(this.state.newAnswer.clarification)
            ) {
              return false;
            }
          }
        }
        break;
      case questionType.MULTI_SELECT_WITH_CLARIFICATION:
        if (this.state.newAnswer && this.state.newAnswer.options) {
          for (let option of this.state.answerOptions) {
            if (
              this.state.newAnswer.options.indexOf(option.value) > -1 &&
              option.clarificationSettable === settableEnum.REQUIRED &&
              isNullOrWhitespace(
                this.state.newAnswer.clarifications[option.value]
              )
            ) {
              return false;
            }
          }
        }
        break;
      default:
        break;
    }

    if (this.state.newAnswer === null) {
      if (isEmptyAnswer(this.state.givenAnswer)) {
        return false;
      }
    } else {
      if (isEmptyAnswer(this.state.newAnswer)) {
        return false;
      }
    }

    return true;
  }

  updateQuestionState(question) {
    let givenAnswer = question.answer;

    if (question.questionType === questionType.BOOL) {
      if (question.answer === null) {
        givenAnswer = null;
      } else if (question.answer) {
        givenAnswer = { option: 1 };
      } else {
        givenAnswer = { option: 0 };
      }
    }

    if (
      question.questionType === questionType.SINGLE_SELECT ||
      question.questionType === questionType.SINGLE_SELECT_WITH_CLARIFICATION
    ) {
      if (question.answer === null) {
        givenAnswer = null;
      } else {
        givenAnswer = {
          option: question.answer,
          clarification: question.clarification,
        };
      }
    }

    this.setState({
      isAnswered: question.isAnswered,
      givenAnswer: givenAnswer,
      newAnswer: null,
      questionKey: question.key,
      questionTextKey: question.questionTextKey,
      questionId: question.id,
      questionNumber: question.number,
      questionType: question.questionType,
      questionView: question.view,
      viewConfig: question.viewConfig,
      answerOptions: question.answerOptionsType
        ? question.answerOptionsType.answerOptions.sort(
            (a, b) => a.value - b.value
          )
        : null,
      firstUnansweredQuestionNumber: question.number,
      questionTextPlaceholders: question.placeHolders,
      isNextButtonClicked: false,
    });
  }

  getMaximumNumberOfItems() {
    if (this.state.questionKey === KeySymptomsQuestion) return 3;

    if (this.state.questionKey === KeyCopingQuestion) return 2;

    return null;
  }

  getMinimumNumberOfItems() {
    if (this.state.questionKey === KeySymptomsQuestion) return 3;

    if (this.state.questionKey === KeyCopingQuestion) return 2;

    return 1;
  }
}

export default withTranslation()(Questionnaire);
