import { Fragment } from "react";
import { X, Check, Eye } from "lucide-react";
import { useTranslation } from "react-i18next";

export const Stepper = ({
  allSteps,
  currentStep,
  isOpen,
  setIsOpen,
}: {
  allSteps: string[];
  currentStep: string;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
}) => {
  const { t } = useTranslation();

  const getReadableStepName = (stepName: string) => {
    switch (stepName) {
      case "billing":
        return t("client-spa-progress-tracker-step-billing");

      case "iagree":
        return t("client-spa-progress-tracker-step-agree-terms-and-conditions");

      case "wireUserName":
        return t("client-spa-progress-tracker-step-wire-username-provide");

      case "referralLetter":
        return t("client-spa-progress-tracker-step-referral-letter");

      case "intake":
        return t("client-spa-progress-tracker-step-intake-questionnaire");

      case "intake-2":
        return t("client-spa-progress-tracker-step-intake-questionnaire");

      case "SQ48-stand-alone":
        return t("client-spa-progress-tracker-step-sq48-questionnaire");

      case "agree":
        return t("client-spa-progress-tracker-step-agreement-questionnaire");

      case "agree-v2":
        return t("client-spa-progress-tracker-step-agreement-questionnaire");

      case "agree-v3":
        return t("client-spa-progress-tracker-step-agreement-questionnaire");

      case "cq-index":
        return t("client-spa-progress-tracker-step-cqi-questionnaire");

      case "cq-index-v51":
        return t("client-spa-progress-tracker-step-cqi-questionnaire");

      case "MHC-SF":
        return t("client-spa-progress-tracker-step-mh-csf-questionnaire");

      case "MHC-SF-Wrapped":
        return t("client-spa-progress-tracker-step-mh-csf-questionnaire");

      case "SQ48-stand-alone-mhc":
        return t("client-spa-progress-tracker-step-sq48-mh-csf-questionnaire");

      case "ifollowup":
        return t("client-spa-progress-tracker-step-follow-up-questionnaire");

      case "adhd-intake":
        return t("client-spa-progress-tracker-step-adhd-intake-questionnaire");

      case "adhd-intake-stand-alone":
        return t("client-spa-progress-tracker-step-adhd-intake-questionnaire");

      case "adhd-self-report":
        return t("client-spa-progress-tracker-step-adhd-self-report");

      default:
        return stepName;
    }
  };

  const steps = allSteps.map((a) => {
    return {
      name: getReadableStepName(a),
      isActive: a === currentStep,
      isCompleted: false,
    };
  });

  let activeStepIndex = steps.findIndex((step) => step.isActive);

  if (currentStep == "closed") {
    activeStepIndex = steps.length;
  }

  if (activeStepIndex > 0) {
    // mark all entries in 'steps' that come before activeStepIndex as completed:
    for (let i = 0; i < activeStepIndex; i++) {
      steps[i].isCompleted = true;
    }
  }

  if (!isOpen) {
    return (
      <div className="bg-blue-50 w-full text-right text-sm text-gray-500">
        <div
          className="inline-flex items-center space-x-1 cursor-pointer text-gray-400 hover:text-gray-600 pr-4"
          onClick={() => setIsOpen(true)}
        >
          <Eye className="h-4 w-4" />
          <p className="text-xs">
            {t("client-spa-progress-tracker-view-progress")}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative bg-blue-50 w-full text-center flex flex-col items-center justify-center">
      <div className="absolute h-20 w-20 top-0 right-0 py-3">
        <X
          className="w-4 h-4 cursor-pointer text-gray-400 hover:text-gray-600"
          onClick={() => setIsOpen(false)}
        />
      </div>
      <div className="my-5 flex items-start space-x-2 xl:space-x-4">
        {steps.map((step, index) => {
          return (
            <Fragment key={step.name}>
              <Step num={index + 1} data={step} />
              {index + 1 < steps.length && (
                <div className="w-4 xl:w-16 bg-blue-100 mt-[12px] xl:mt-[22px] h-[2px]" />
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

const Step = ({
  num,
  data,
}: {
  num: number;
  data: {
    name: string;
    isActive: boolean;
    isCompleted: boolean;
  };
}) => {
  const { name, isActive, isCompleted } = data;

  const getStepIconStyle = () => {
    if (isCompleted) return "bg-blue-100 text-blue-300 font-normal";

    return isActive
      ? "bg-blue-200 text-gray-500 font-bold"
      : "bg-gray-200 text-gray-400 font-normal";
  };

  return (
    <div className="flex items-center justify-center space-x-4">
      <div
        className={`rounded-full h-6 w-6 xl:h-10 xl:w-10 flex items-center justify-center text-sm ${getStepIconStyle()}`}
      >
        {isCompleted ? (
          <Check className="w-3 h-3 xl:w-4 xl:h-4" />
        ) : (
          <p className="text-xs xl:text-base">{num}</p>
        )}
      </div>
      <h1
        className={`text-base hidden xl:block ${
          isActive ? "font-bold text-gray-500" : "font-normal text-gray-400"
        }`}
      >
        {name}
      </h1>
    </div>
  );
};
