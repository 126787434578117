import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./InputFieldQuestion.scss";
import {
  getTranslatedQuestion,
  getQuestionKeyStyle,
} from "../../../utils/translatedQuestionBuilder";

class InputFieldNumberQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: this.props.givenAnswer ? this.props.givenAnswer : "",
    };
  }

  render() {
    const questionText = getTranslatedQuestion(
      this.props.t,
      this.props.questionTextKey,
      this.props.questionTextPlaceholders
    );

    return (
      <div
        data-cy="question-key"
        id={this.props.questionTextKey}
        className="input-field-question"
      >
        <p
          dangerouslySetInnerHTML={{
            __html: questionText,
          }}
          className={getQuestionKeyStyle(questionText)}
        />

        <input
          className="input-field-question__input"
          type="number"
          data-cy={"number-question"}
          onChange={(e) => this.handleInputChange(e)}
          value={this.state.inputValue}
          min="0"
          max="100"
        />
      </div>
    );
  }

  handleInputChange(e) {
    this.setState(
      {
        inputValue: e.target.value,
      },
      () => this.props.emitGivenAnswer(this.state.inputValue)
    );
  }
}

export default withTranslation()(InputFieldNumberQuestion);
