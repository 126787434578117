export default {
    BOOL: "bool",
    INT: "int",
    SINGLE_SELECT: "single-select",
    SINGLE_SELECT_WITH_CLARIFICATION: "single-select-with-clarification",
    STRING: "string",
    MULTI_SELECT: "multi-select",
    MULTI_SELECT_WITH_CLARIFICATION: "multi-select-with-clarification",
    PARENT: "parent",
    INFO: "info"
}
