import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../Questionnaire.scss";
import Button from "../Button";
// @ts-ignore
import wire_image from "../../../resources/wire_mockup.png";
import axios from "axios";
import LanguageChanger from "../../componenets/LanguageChanger";

export const WireUsernameQuestion = ({
  patientId,
  onSubmit,
}: {
  patientId: string;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation();
  const [userName, setUserName] = useState("");

  const handleSubmit = async () => {
    axios
      .post(`questionlists/${patientId}/wire-username`, {
        wireUsername: userName,
      })
      .then(() => onSubmit());
  };

  return (
    <div className="questionnaire-root registration-container">
      <div>
        <LanguageChanger />

        <div className="input-field-question">
          <div>
            <div>
              <div style={{ float: "right", width: "40%", marginTop: "4rem" }}>
                <img src={wire_image} alt="wire-app" width="100%" />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("wire-guide"),
                }}
              />

              <Button
                className="questionnaire__button-container"
                variant="contained"
                onClick={() =>
                  window.open("https://wire.com/en/download/", "_blank")
                }
              >
                Download wire
              </Button>

              <p
                dangerouslySetInnerHTML={{
                  __html: t("wire-warning"),
                }}
                style={{ paddingTop: "3rem" }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("wire-guide-questions"),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("wire-username"),
                }}
              />

              <div className="flex items-center space-x-2">
                <input
                  className="input-field-question__input"
                  placeholder="@wire-username"
                  onChange={(e) => setUserName(e.currentTarget.value)}
                />
                <Button
                  onClick={() => handleSubmit()}
                  isDisabled={userName == ""}
                >
                  {t("wire-submit")}
                </Button>
                <Button onClick={() => handleSubmit()}>{t("wire-skip")}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
