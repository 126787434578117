import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  getTranslatedQuestion,
  getQuestionKeyStyle,
} from "../../../utils/translatedQuestionBuilder";

class InfoPage extends Component {
  render() {
    const questionText = getTranslatedQuestion(
      this.props.t,
      this.props.questionTextKey,
      this.props.questionTextPlaceholders
    );

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: questionText,
        }}
        className={`pb-6 ${getQuestionKeyStyle(questionText)}`}
      ></div>
    );
  }
}

export default withTranslation()(InfoPage);
