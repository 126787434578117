import logo from "../assets/logo.webp";
import { CircleCheck } from "lucide-react";
import { useTranslation } from "react-i18next";

export const CompletedCohortStudy = () => {
  const { t } = useTranslation();

  return (
    <main className="min-h-screen bg-blue-100 flex items-center justify-center ">
      <section className="shadow bg-white px-6 py-12 lg:p-24 rounded-2xl w-[80vw] lg:w-[70vw] text-center">
        <div className="flex items-center justify-center">
          <img src={logo} alt="logo" className="max-w-[160px]" />
        </div>

        <section className="mt-6 lg:mt-12 rounded-lg p-6 lg:p-12 text-slate-700 text-center flex flex-col items-center justify-center">
          <div className="h-32 w-32">
            <CircleCheck className="h-32 w-32" />
          </div>
          <p className="text-4xl font-bold text-slate-700">
            {t("cohort-study-client-completed")}
          </p>
        </section>
      </section>
    </main>
  );
};
