import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "../questionnaire/componenets/Button";
import { useTranslation } from "react-i18next";

export const ScreeningDossierAgree = ({
  userName,
  handleSubmit,
  isSubmitInProgress,
  isResearchChecked,
  toggleIsResearchChecked,
  isInformationExchangeChecked,
  toggleInformationExchangeChecked,
}: {
  userName: string;
  handleSubmit: any;
  isSubmitInProgress: boolean;
  isResearchChecked: boolean;
  toggleIsResearchChecked: () => void;
  isInformationExchangeChecked: boolean;
  toggleInformationExchangeChecked: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: t("adhd-screening-dossier-iagree-intro", {
            replace: { firstName: userName },
          }),
        }}
      />

      <p
        style={{ paddingTop: "20px", marginBottom: 0 }}
        dangerouslySetInnerHTML={{
          __html: t("adhd-screening-dossier-iagree-terms-1"),
        }}
      />

      <FormControlLabel
        label={t(
          isResearchChecked
            ? "adhd-screening-dossier-iagree-agree-checkbox-yes-label"
            : "adhd-screening-dossier-iagree-agree-checkbox-no-label"
        )}
        control={
          <Checkbox
            checked={isResearchChecked}
            onChange={toggleIsResearchChecked}
            name="research"
            color="primary"
          />
        }
      />

      <p
        style={{ paddingTop: "32px", marginBottom: 0 }}
        dangerouslySetInnerHTML={{
          __html: t("adhd-screening-dossier-iagree-terms-2"),
        }}
      />

      <FormControlLabel
        label={t(
          isInformationExchangeChecked
            ? "adhd-screening-dossier-iagree-agree-checkbox-yes-label"
            : "adhd-screening-dossier-iagree-agree-checkbox-no-label"
        )}
        control={
          <Checkbox
            checked={isInformationExchangeChecked}
            onChange={toggleInformationExchangeChecked}
            name="shareGeneralPractitioner"
            color="primary"
          />
        }
      />

      <br />

      <div className={"questionnaire__button-container"}>
        <Button onClick={() => handleSubmit()} isDisabled={isSubmitInProgress}>
          {t("agree-end-button")}
        </Button>
      </div>
    </>
  );
};
