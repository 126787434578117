import { useTranslation } from "react-i18next";
import Button from "../componenets/Button";

const LanguageChanger = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    if (i18n.language === "nl") {
      i18n.changeLanguage("en");
      localStorage.setItem("userOverridden_i18nextLng", "en");
    } else {
      i18n.changeLanguage("nl");
      localStorage.setItem("userOverridden_i18nextLng", "nl");
    }
  };

  return (
    <div className="flex lg:block lg:absolute right-0 pb-6 lg:pb-0 pt-2">
      <Button onClick={() => toggleLanguage()} styleSecondary={true}>
        <div className="flex items-center space-x-2">
          {i18n.language === "nl" ? <GbFlag /> : <NlFlag />}
          <p className="p-0 m-0">
            {i18n.language === "nl" ? "English" : "Nederlands"}
          </p>
        </div>
      </Button>
    </div>
  );
};

const NlFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="flag-icons-nl"
      viewBox="0 0 640 480"
      className="w-4 h-4"
    >
      <path fill="#21468b" d="M0 0h640v480H0z" />
      <path fill="#fff" d="M0 0h640v320H0z" />
      <path fill="#ae1c28" d="M0 0h640v160H0z" />
    </svg>
  );
};

const GbFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="flag-icons-gb"
      viewBox="0 0 640 480"
      className="w-4 h-4"
    >
      <path fill="#012169" d="M0 0h640v480H0z" />
      <path
        fill="#FFF"
        d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"
      />
      <path
        fill="#C8102E"
        d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"
      />
      <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
      <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
    </svg>
  );
};

export default LanguageChanger;
